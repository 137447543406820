@import '~antd/dist/antd.css';

.formContainer {
  overflow-y: hidden;
  background: rgb(127, 127, 127);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

h1 {
  text-align: center;
  margin: 13px 0;
}

.formContainer form {
  width: 70%;
  max-width: 450px;
  border: 1px solid #dfefef;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  border-radius: 15px;
}

.uiForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 300px;
}

.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formField input {
  border: 1px solid grey;
  padding: 20px;
  border-radius: 4px;
}

.formField input:focus {
  outline: none;
}

.formField label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 3px;
}

button {
  background: #0563b4;
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 30px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover {
  background-color: #0e528d;
}

.errorMsg {
  color: red;
  margin: 0;
  align-self: flex-start;
}

.msgOk {
  color: green;
  margin-top: 15px;
}

/* body.mapPage {
  background-color: #ccc;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  margin: 0;
}
#searchForm {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 1000;
  background: #ccc;
}
#mapid {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.btn {
  width: 80px;
  font-size: larger;
  font-weight: bold;
}
.btn-border {
border: 2px solid #000;
border-radius: 0;
background: #fff;
}

.btn-border:hover {
color: #fff;
background: #000;
}

.chk + label{
    font-weight: 100;
}
.chk:checked + label{
    font-weight: bold;
}
.disptoggle {
  display: none !important;
}

/* html, body {
  margin: 10px;
  padding: 0;
  height: 100%;
  width: 100%;
} */

#root {
  width: inherit;
  height: inherit;
}


/* .leaflet-container {
  width: 600px;
  height: 300px;
  margin: 10px;
} */
