body {
    background-color: #ccc;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    margin: 0;
}
#root {
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    margin: 0;
}

.leaflet-container {
    position: absolute;
    top: 100px;
    bottom: 0;
    width: 100%;
}
#searchForm {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 1000;
    background: #ccc;
}
.motifmapTitle {
    width:max-content;
    font-size: x-large;
    font-weight: bolder;
}
.searchArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    border: 2px solid #333;
    padding:5px;
}
.btnArea {
    display: flex;
    align-items: center;
}
/* #mapid {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
} */

.btn {
    width: 80px;
    font-size: larger;
    font-weight: bold;
    white-space: nowrap;
    color: #000;
    padding: 0;
    margin: 10px;
}
.btn-border {
    border: 2px solid #000;
    border-radius: 0;
    background: #fff;
}

.btn-border:hover {
    color: #fff;
    background: #000;
}

.btn-potision {
    display:table-cell;
    margin: 2px 20px;
}

.chk + label{
    font-weight: 100;
}
.chk:checked + label{
    font-weight: bold;
}
.disptoggle {
    display: none !important;
}
